.contact__link {
  position: relative;
  text-decoration: none !important;
  overflow: hidden;
  margin: 16px 32px !important;
  display: flex;
  align-items: center;
}
.phone__number {
  margin: 0 30px;
  font-family: sans-serif;
  white-space: nowrap;
}

.MuiInputBase-root {
  border-radius: 16px !important;
}
#phoneNumber {
  font-family: sans-serif;
}

.contactForm {
  width: 100%;
  text-align: start;
}

.contact__link span {
  transition: 0.3s;
  white-space: nowrap;
  transform: translateX(0);
}

.contact__link__icon {
  overflow: hidden;
  margin-right: 5px;

  transform: translateY(0);
  opacity: 1;
  transition: 0.25s;
}

.contact__link:hover .contact__link__icon {
  transform: translateY(0);
  opacity: 1;
}
.contact__link:hover span {
  transform: translateX(0);
}

.contact__link--action {
  font-size: 20px;
  display: inline;
  align-items: center;
}

.contact_container {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin-top: 40px;

}

.contact__img {
  /* height: 250px; */
  width: 100%;
  max-width: 100%;
  padding: 0 12px;
  user-select: none;
}

@media (min-width: 780px) {
  .contact_container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0 40px;
  }
  .contact__img {
    width: 100%;
    object-fit: scale-down;
  }
  .contact__link--action {
    display: none;
  }
  .contact__link span {
    transform: translateX(-25px);
  }
  .contact__link__icon {
    transform: translateY(-100%);
    opacity: 0;
  }

  .phone__number {
    margin: 0 10px;
  }
  .contactForm {
    width: 100%;
  }
}
