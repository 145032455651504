.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 5px;
  padding: 2px;
}

::-webkit-scrollbar-track {
  background-color: none;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background-color: #313131;
  border-radius: 50px;
}