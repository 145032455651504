.portfolio_container {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
@media (min-width: 480) {
  .portfolio_container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 960px) {
  .portfolio_container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1480px) {
  .portfolio_container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
